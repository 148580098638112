// stylelint-disable declaration-no-important


//------------------------------------------------------------------------------
// CARD

.card {
  background-clip: border-box;
  background-color: $card-bg;
  border: rem($card-border-width) solid $card-border-color;
  border-radius: rem($border-radius);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;

  > .list-group:first-child {
    .list-group-item:first-child {
      border-radius: rem($border-radius) rem($border-radius) 0 0;
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      border-radius: 0 0 rem($border-radius) rem($border-radius);
    }
  }
}

.card-header {
  background-color: $card-cap-bg;
  border-bottom: rem($card-border-width) solid $card-border-color;
  margin-bottom: 0;
  padding: rem($card-spacer-y) rem($card-spacer-x);
}

.card-body {
  flex: 1 1 auto;
  padding: rem($card-spacer-y) rem($card-spacer-x);
}

.card-title {
  margin-bottom: rem($card-spacer-y);
}

.card-footer {
  background-color: $card-cap-bg;
  border-radius: 0 0 rem($border-radius) rem($border-radius);
  border-top: rem($card-border-width) solid $card-border-color;
  padding: rem($card-spacer-y) rem($card-spacer-x);
}


//------------------------------------------------------------------------------
// ACCORDION

.accordion {
  > .card {
    overflow: hidden;

    &:not(:first-of-type) {
      .card-header:first-child {
        border-radius: 0;
      }

      &:not(:last-of-type) {
        border-bottom: 0;
        border-radius: 0;
      }
    }

    &:first-of-type {
      border-bottom: 0;
      border-radius: rem($border-radius) rem($border-radius) 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 rem($border-radius) rem($border-radius);
    }

    .card-header {
      margin-bottom: rem(-$card-border-width) !important;
    }
  }
}
