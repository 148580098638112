//------------------------------------------------------------------------------
// RATING

.custom-rating {
  position: relative;

  input[type="radio"] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  .icon {
    height: rem(40);
    width: rem(40);
  }
}
