// stylelint-disable declaration-no-important

// ------------------------------------------------------------------------------
// IMPORTS

@import "functions";
@import "mixins";
@import "variables";

@import "alert";
@import "badge";
@import "buttons";
@import "card";
@import "columns";
@import "dropdown";
@import "forms";
@import "grid";
@import "images";
@import "list-group";
@import "modal";
@import "nav";
@import "navbar";
@import "pagination";
@import "progress";
@import "tables";
@import "toasts";
@import "tooltip";
@import "transitions";
@import "type";
@import "utilities";

@import "data-table";
@import "fancybox";


//------------------------------------------------------------------------------
// DEFAULTS

*,
*:before,
*:after {
  box-sizing: border-box;
}


//------------------------------------------------------------------------------
// HEADER

@keyframes color-animation {
  0% {
    background-position: 22% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.color-animation {
  //animation: color-animation 8s linear infinite;
  background: linear-gradient(90deg, lighten($catch-direct, 20%), $catch-direct, lighten($catch-direct, 20%), lighten($catch-direct, 40%), lighten($catch-direct, 20%), $catch-direct, lighten($catch-direct, 20%), lighten($catch-direct, 40%), lighten($catch-direct, 20%));
  background-position: 22% 0;
  background-size: 300% 100%;
}

.color-header {
  @extend .color-animation;
  height: rem(14);
}

.logo {
  position: relative;
  top: rem(6);
}

.logo-dev {
  position: relative;
  top: 0;
}

.logo-login {
  position: relative;
  top: rem(-3);
}


//------------------------------------------------------------------------------
// MAIN

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  display: block;
  flex: 1 0 auto;
}


//------------------------------------------------------------------------------
// CO2 FOOTPRINT

.CO2-footprint {
  line-height: 1.1;

  .icon {
    left: rem(-5);
    position: relative;
    transform: rotate(10deg);
  }

  strong {
    font-size: 140%;
  }
}


//------------------------------------------------------------------------------
// FORM

.level-2 > legend {
  @include font-size($h4-min-font-size, $h4-max-font-size);
  background: $gray-800;
  color: $white;
  padding: rem($spacer / 2) rem($spacer);
  text-transform: uppercase;
}

.level-3 > legend {
  @include font-size($h4-min-font-size, $h4-max-font-size);
  background: $gray-600;
  color: $white;
  padding: rem($spacer / 2) rem($spacer);
  text-transform: uppercase;
}

.level-4 {
  border: rem(1) solid $input-border-color;
  margin: rem($spacer / 2) 0 rem($spacer * 2);
  padding: rem($spacer) rem($spacer) 0;

  & > legend {
    max-width: inherit;
    padding: 0 rem(10);
    width: auto;
  }
}

.level-5 {
  margin-bottom: rem($spacer);

  & > legend {
    @include font-size($h5-min-font-size, $h5-max-font-size);
    background: $gray-400;
    color: $dark;
    margin-bottom: rem($spacer * 1.5);
    padding: rem($spacer / 2) rem($spacer);
    text-transform: uppercase;
  }
}

.level-6 {
  margin-bottom: rem($spacer);

  legend {
    @include font-size($h5-min-font-size, $h5-max-font-size);
    background: $gray-200;
    color: $dark;
    margin-bottom: rem($spacer * 1.5);
    padding: rem($spacer / 2) rem($spacer);
    text-transform: uppercase;
  }
}


//------------------------------------------------------------------------------
// LOGIN

@include media-breakpoint-up(lg) {
  .login {
    min-height: 100vh;
  }
}
